<template>
    <div>
        <h1>{{ $t('page-title.gen-image') }}</h1>
        
        <div class="input-fields">
            <h3>{{ $t('s-gen-image.dimensions') }}</h3>
            <el-input-number 
                type="number" 
                class="input" 
                id="inputWidth" 
                v-model="width" 
                :min="Settings.MIN_SIDE_LENGTH"
                :max="Settings.MAX_SIDE_LENGTH"
                :step="10"
                :controls="false"
            />
            x
            <el-input-number 
                type="number" 
                class="input" 
                id="inputHeight" 
                v-model="height"
                :min="Settings.MIN_SIDE_LENGTH"
                :max="Settings.MAX_SIDE_LENGTH"
                :step="10"
                :controls="false"
            />
            <span class="space"></span>
            <el-button
                type="primary" 
                id="buttonGenerate" 
                @click="generateImage"
            >
                {{ $t('s-gen-image.generate') }}
            </el-button>
            
            <div
                v-if="Settings.ALLOW_ADVANCED_FEATURES" 
                class="show-advanced-features"
                style="margin-top: 10px;"
            >
                <el-checkbox 
                    type="checkbox" 
                    v-model="showAdvanced"
                    id="showAdvanced"
                >
                    {{ $t('s-gen-image.cb-show-advanced-features') }}
                </el-checkbox>
            </div>

            <div 
                v-if="Settings.ALLOW_ADVANCED_FEATURES && showAdvanced" 
                class="advanced-features"
            >
                <el-divider content-position="left">{{ $t('s-gen-image.divider-advanced-options-text') }}</el-divider>
                <el-row :gutter="10" align="middle">
                    <el-col :xs="col.label.xs" :sm="col.label.sm" :md="col.label.md" :lg="col.label.lg" :xl="col.label.xl">
                        {{ $t('s-gen-image.advanced.custom-text.title') }}
                    </el-col>
                    <el-col :xs="col.body.xs" :sm="col.body.sm" :md="col.body.md" :lg="col.body.lg" :xl="col.body.xl">
                        <div class="custom-text">
                            <el-input 
                                type="text" 
                                v-model="inputText"
                                :placeholder="$t('s-gen-image.advanced.custom-text.placeholder')"
                            ></el-input>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="row.gutter" :align="row.align">
                    <el-col :xs="col.label.xs" :sm="col.label.sm" :md="col.label.md" :lg="col.label.lg" :xl="col.label.xl">
                        <label 
                            for="customBgColor"
                            v-text="$t('s-gen-image.advanced.custom-bg.title')"
                        ></label>
                        <el-popover
                            placement="bottom-start"
                            :title="$t('s-gen-image.advanced.custom-bg.popup.title')"
                            :width="160"
                            trigger="hover"
                            :content="$t('s-gen-image.advanced.custom-bg.popup.content')"
                        >
                            <template #reference>
                                <sup>&nbsp;?</sup>
                            </template>
                        </el-popover>
                    </el-col>
                    <el-col :xs="col.body.xs" :sm="col.body.sm" :md="col.body.md" :lg="col.body.lg" :xl="col.body.xl">
                        <el-row :align="row.align" :gutter="5" >
                            <el-col :span="4">
                                <el-color-picker v-model="inputBgColor" id="customBgColor" />
                            </el-col>
                            <el-col :span="8">
                                <el-button 
                                    type="info" 
                                    plain 
                                    @click="resetColor('bg')" 
                                    size="mini"
                                >
                                    {{ $t('s-gen-image.advanced.custom-bg.btn-reset') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                
                <el-row :gutter="row.gutter" :align="row.align">
                    <el-col :xs="col.label.xs" :sm="col.label.sm" :md="col.label.md" :lg="col.label.lg" :xl="col.label.xl">
                        <label 
                            for="customTxtColor"
                            v-text="$t('s-gen-image.advanced.custom-text-color.title')"
                        ></label>
                        <el-popover
                            placement="bottom-start"
                            :title="$t('s-gen-image.advanced.custom-text-color.popup.title')"
                            :width="160"
                            trigger="hover"
                            :content="$t('s-gen-image.advanced.custom-text-color.popup.content')"
                        >
                            <template #reference>
                                <sup>&nbsp;?</sup>
                            </template>
                        </el-popover>
                    </el-col>
                    <el-col :xs="col.body.xs" :sm="col.body.sm" :md="col.body.md" :lg="col.body.lg" :xl="col.body.xl">
                        <el-row :align="row.align" :gutter="5" >
                            <el-col :span="4">
                                <el-color-picker v-model="inputTextColor" id="customTxtColor" />
                            </el-col>
                            <el-col :span="8">
                                <el-button
                                    type="info"
                                    plain 
                                    @click="resetColor('text')" 
                                    size="mini"
                                >
                                    {{ $t('s-gen-image.advanced.custom-text-color.btn-reset') }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row :gutter="row.gutter" :align="row.align">
                    <el-col :xs="col.label.xs" :sm="col.label.sm" :md="col.label.md" :lg="col.label.lg" :xl="col.label.xl">
                        {{ $t('s-gen-image.advanced.font-size.title') }}
                    </el-col>
                    <el-col :xs="col.body.xs" :sm="col.body.sm" :md="col.body.md" :lg="col.body.lg" :xl="col.body.xl">
                        <div class="font-size">
                            <el-slider
                                v-model="customFontSize"
                                :min="20"
                                :max="100"
                                :step="5"
                                show-input
                            ></el-slider>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>

        <div 
            v-if="dataUrl"
            class="data-url"
        >
            <h3>{{ $t('s-gen-image.data-url.title') }}</h3>
            <el-input 
                type="text" 
                class="input" 
                id="inputDataUrl" 
                :placeholder="$t('s-gen-image.data-url.placeholder')"
                v-model="dataUrl"
                readonly
            />
        </div>

        <div 
            v-show="dataUrl"
            class="preview-image"
        >
            <h3>{{ $t('s-gen-image.preview.text') }}</h3>
            <img 
                :src="dataUrl"
                :alt="$t('s-gen-image.preview.image-alt-text')"
                id="imagePreview" 
            />
        </div>
    </div>
</template>

<script>
import Settings from '@/settings/image'
export default {
    data(){
        return {
            Settings,
            width: Settings.DEFAULT_WIDTH,
            height: Settings.DEFAULT_HEIGHT,
            dataUrl: '',
            inputText: '',
            inputBgColor: Settings.DEFAULT_BACKGROUND_COLOR,
            inputTextColor: Settings.DEFAULT_TEXT_COLOR,
            showAdvanced: false,
            col: {
                label: { xs: 24, sm: 8, md: 8, lg: 4, xl: 4 },
                body: { xs: 24, sm: 16, md: 16, lg: 8, xl: 8 },
            },
            row: {
                gutter: 10,
                align: "middle",
            },
            customFontSize: 20
        }
    },

    methods: {
        generateImage(){
            if (!this.validate()) return 
            const imagePreview  = document.getElementById("imagePreview");
            const canvasElement = this.createPlaceholderCanvas()
            this.dataUrl        = canvasElement.toDataURL()

            imagePreview.style.maxWidth = `${this.width}px`;
        },

        createPlaceholderCanvas() {
            const canvas    = document.createElement("canvas")
            const context   = canvas.getContext("2d")
            
            canvas.width    = this.width
            canvas.height   = this.height

            let { backgroundColor } = this

            // Fill in the background
            context.fillStyle = backgroundColor
            context.fillRect (0, 0, canvas.width, canvas.height)

            // Place the text
            context.font         = `bold ${this.fontSize} sans-serif`
            context.fillStyle    = this.textColor
            context.textAlign    = "center"
            context.textBaseline = "middle"

            context.fillText(this.text, canvas.width / 2, canvas.height / 2)

            return canvas
        },

        validate(){
            // Validation
            if( isNaN(this.width) || isNaN(this.height)) return false
            if ( 
                this.width < Settings.MIN_SIDE_LENGTH
                || this.height < Settings.MIN_SIDE_LENGTH
            ) {
                alert( this.$t('s-gen-image.errors.validation.min-length', [Settings.MIN_SIDE_LENGTH]))
                return false
            } 
            
            else if (
                this.width > Settings.MAX_SIDE_LENGTH
                || this.height > Settings.MAX_SIDE_LENGTH
            ) {
                alert( this.$t('s-gen-image.errors.validation.max-length', [Settings.MAX_SIDE_LENGTH]))
                return false
            }
            return true
        },

        resetColor(type="bg"){
            switch(type){
                case 'bg': 
                    this.inputBgColor = Settings.DEFAULT_BACKGROUND_COLOR
                    break
                case 'text':
                    this.inputTextColor = Settings.DEFAULT_TEXT_COLOR
                    break
            }
        }
    },

    computed: {
        text(){
            let text = `${this.width}x${this.height}`
            if (this.showAdvanced && !!this.inputText) text = this.inputText
            return text
        },

        fontSize(){
            let num = 90
            if (this.width<400) num = 40
            if (this.showAdvanced && this.customFontSize) num = this.customFontSize
            return `${num}px`
        },

        backgroundColor(){
            let bgColor = Settings.DEFAULT_BACKGROUND_COLOR
            if (this.showAdvanced && !!this.inputBgColor) bgColor = this.inputBgColor
            return bgColor
        },

        textColor(){
            let txtColor = Settings.DEFAULT_TEXT_COLOR
            if (this.showAdvanced && !!this.inputTextColor) txtColor = this.inputTextColor
            return txtColor
        }
    },
}
</script>

<style>

</style>