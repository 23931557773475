export default {
    MIN_SIDE_LENGTH: 200,
    MAX_SIDE_LENGTH: 1400,

    DEFAULT_WIDTH: 200,
    DEFAULT_HEIGHT: 200,

    ALLOW_ADVANCED_FEATURES: true,
    DEFAULT_BACKGROUND_COLOR: "#aaaaaa",
    DEFAULT_TEXT_COLOR: "#333333"
}